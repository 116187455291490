import type { GetStaticProps } from 'next'
import Head from 'next/head'
import { NextSeo } from 'next-seo'
import { useMemo, Suspense } from 'react'
import dynamic from 'next/dynamic'

import { useAnalyticsPropagation } from 'analytics'
import { formatPageData, getPageGlobalData } from 'utils/formatPageData'
import { getBaseUrl } from 'utils'
import config from 'config/next-seo.config'

import prismicClient from 'config/prismic.config'
import { getPageWithSlices } from '@/lib/utils/products'
import { pageQuery } from '@/lib/graphql/prismic'
import { getPageWithReviews } from '@/lib/utils/pages'
import { AllSlices } from '@/components/slices'
import type { HomeDocument } from 'types/types.generated'

const LazyModalProduct = dynamic(() => import('@/components/modals/ModalProduct'), {
	ssr: false,
	loading: () => null
})

const LazyOrganizationJsonLd = dynamic(
	() => import('next-seo').then((mod) => mod.OrganizationJsonLd),
	{
		ssr: false,
		loading: () => null
	}
)

// Pre-compute static organization data
const STATIC_ORGANIZATION_DATA = {
	url: 'https://www.realthread.com',
	name: 'Real Thread',
	sameAs: [
		'https://www.facebook.com/realthreadofficial',
		'https://www.instagram.com/realthread',
		'https://www.youtube.com/realthread',
		'https://x.com/realthread',
		'https://www.linkedin.com/company/realthread'
	],
	contactPoint: [
		{
			telephone: '+1-407-679-3895',
			contactType: 'Customer Service',
			areaServed: 'US',
			availableLanguage: ['English', 'Spanish']
		}
	],
	address: {
		streetAddress: '1101 N Keller Rd, Ste A',
		addressLocality: 'Orlando',
		addressRegion: 'FL',
		postalCode: '32810',
		addressCountry: 'US'
	},
	location: [
		{
			'@id': 'https://www.realthread.com/orlando',
			name: 'Real Thread - Orlando',
			address: {
				streetAddress: '1101 N Keller Rd, Ste A',
				addressLocality: 'Orlando',
				addressRegion: 'FL',
				postalCode: '32810',
				addressCountry: 'US'
			},
			telephone: '+1-407-679-3895',
			url: 'https://www.realthread.com/orlando'
		},
		{
			'@id': 'https://www.realthread.com/dallas-fort-worth',
			name: 'Real Thread - Dallas/Fort Worth',
			address: {
				streetAddress: '2506 Tillar Street',
				addressLocality: 'Fort Worth',
				addressRegion: 'TX',
				postalCode: '76107',
				addressCountry: 'US'
			},
			telephone: '+1-817-453-3121',
			url: 'https://www.realthread.com/dallas-fort-worth'
		}
	]
}

/**
 * Home Page Component
 *
 * The main landing page component that serves as the entry point for the website.
 * Implements dynamic content slices, SEO optimization, and structured data for organization information.
 *
 * @component
 * @param {Object} props
 * @param {Object} props.page - Page data including slices and meta information
 */

type Props = {
	page: HomeDocument
}

const Home = ({ page }: Props) => {
	const { data, globalData } = page || {}
	const { meta_title, meta_description, og_image } = data || {}
	const { og_image: global_og_image } = globalData?.data ?? {}

	// Move analytics to a separate effect to avoid blocking main thread
	useAnalyticsPropagation({
		page_title: `${meta_title?.[0]?.text ?? 'Home'}`
	})

	const ratingValue = '4.94'

	// Optimize memoization by only including dynamic data
	const organizationData = useMemo(
		() => ({
			...STATIC_ORGANIZATION_DATA,
			logo: globalData?.data?.logo?.url,
			aggregateRating: {
				'@type': 'AggregateRating',
				itemReviewed: {
					'@type': 'Organization',
					name: 'Real Thread',
					url: 'https://www.realthread.com/reviews'
				},
				ratingValue,
				reviewCount: '4341',
				bestRating: '5',
				worstRating: '1'
			}
		}),
		[globalData?.data?.logo?.url]
	)

	return (
		<>
			<Head>
				<meta property="og:type" content="Website" />
				<meta property="og:url" content={`${getBaseUrl()}`} />
			</Head>
			{/* SEO Configuration */}
			<NextSeo
				titleTemplate="%s"
				title={meta_title?.[0]?.text ?? 'Home'}
				description={meta_description?.[0]?.text ?? ''}
				openGraph={{
					images: og_image?.url ? [og_image] : global_og_image?.url ? [global_og_image] : undefined
				}}
				{...config}
			/>

			<AllSlices page={page} />

			<Suspense fallback={null}>
				<LazyModalProduct originPath="/" globalData={globalData} />
			</Suspense>

			<Suspense fallback={null}>
				<LazyOrganizationJsonLd {...organizationData} />
			</Suspense>
		</>
	)
}

export const getStaticProps: GetStaticProps = async ({ previewData }) => {
	// Parallel data fetching for performance
	const [page, { globalData, promotionalPopups }] = await Promise.all([
		prismicClient({ previewData }).getSingle('home', {
			graphQuery: pageQuery('home')
		}),
		getPageGlobalData('home')
	])

	// Process and format page data
	const formattedPageData = await formatPageData(page)
	const pageWithReviews = await getPageWithReviews(formattedPageData)
	const pageWithSlices = await getPageWithSlices(pageWithReviews)

	return {
		props: {
			page: {
				...pageWithSlices,
				globalData,
				promotionalPopups
			}
		}
	}
}

export default Home
