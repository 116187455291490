// Breadcrumb Hero

import { forwardRef } from 'react'
import kebabCase from 'lodash.kebabcase'
import Image from 'next/image'
import type { MutableRefObject } from 'react'
import { PrismicLink, HeaderContent, Breadcrumbs, ReviewStars, VideoEmbed } from 'ui'
import { isNotEmptyArray, placeholderProps } from 'utils/client'
import dynamic from 'next/dynamic'
import clsx from 'clsx'
import { theme } from 'config/tailwind.config'
import { ImageJsonLd } from 'next-seo'
import type { PageDocument } from 'types/types.generated'

const PaintStrokeBackground = dynamic(() => import('ui/PaintStrokeBackground'), {
	ssr: false
})

const SqueegeeEffect = dynamic(() => import('ui/SqueegeeEffect'), {
	ssr: false
})

const BreadcrumbHero = forwardRef((props: PageDocument, ref: MutableRefObject<HTMLDivElement>) => {
	const {
		primary,
		category,
		items,
		breadcrumbs: breadcrumbsOverride,
		hideBreadcrumbs,
		withRootLevelBreadcrumbPaths,
		setFilter,
		descriptionClassName,
		headerClassName,
		showReviewStars,
		globalData,
		children,
		className,
		totalAverage,
		reviewsCount
	} = props || {}

	const {
		hero_heading,
		heading_max_width,
		hero_description,
		hero_description_hidden,
		description_max_width,
		background_image,
		background_video,
		background_color,
		brushstroke_layout,
		squeegee,
		show_breadcrumb,
		text_contrast_mode,
		centered,
		ink_stroke_color,
		contained,
		background_height,
		section_primary_cta_label,
		section_primary_cta_link,
		section_secondary_cta_label,
		section_secondary_cta_link
	} = primary || {}

	return (
		<header
			className={clsx('breadcrumb-hero', {
				'pb-10 sm:pb-16 xl:pb-32 overflow-hidden':
					(brushstroke_layout && background_image?.url) || background_video?.html,
				'pb-8': contained
			})}
		>
			<div
				className={clsx('relative max-lg:mb-10 pt-[--nav-height]', className, {
					'lg:min-h-[562px] mb-96': brushstroke_layout && background_image?.url,
					'mb-32': brushstroke_layout && !background_image?.url,
					'lg:h-[calc(100vh-20px)] lg:min-h-[800px]':
						background_height === 'full' && !brushstroke_layout,
					'lg:min-h-[562px] pb-20 pt-32': background_height !== 'full' && !brushstroke_layout,
					'flex flex-col justify-center mb-20': !brushstroke_layout && !contained,
					'text-white': text_contrast_mode === 'light',
					'text-black-rt': text_contrast_mode === 'dark',
					'sm:min-h-[562px] max-h-[625px] mx-4 max-w-screen-2xl 2xl:mx-auto mt-[--nav-height] pt-0 rounded-lg overflow-hidden':
						contained
				})}
				style={{ background: background_color }}
				ref={ref}
			>
				{background_image?.url && !brushstroke_layout ? (
					<>
						<Image
							src={background_image.url}
							alt={background_image.alt || ''}
							fill
							style={{ objectFit: 'cover' }}
							sizes="(max-width: 480px) 480px,
								   (max-width: 768px) 768px,
								   (max-width: 1024px) 1024px,
								   (max-width: 1600px) 1600px,
								   2400px"
							priority
							fetchPriority="high"
							{...placeholderProps(background_image)}
						/>
						<ImageJsonLd
							contentUrl={background_image.url}
							images={[background_image]}
							creator="Real Thread"
							creditText="Real Thread"
						/>
					</>
				) : null}

				<div
					className={`relative lg:pt-10 space-y-5
					${!centered ? 'lg:pl-32 max-lg:container' : ''}
					${!contained ? 'container' : ''}
					${brushstroke_layout ? 'pt-10' : ''}
				`}
				>
					{show_breadcrumb ? (
						<Breadcrumbs
							category={category}
							hideBreadcrumbs={hideBreadcrumbs}
							breadcrumbsOverride={breadcrumbsOverride}
							withRootLevelBreadcrumbPaths={withRootLevelBreadcrumbPaths}
							centered={centered}
						/>
					) : null}

					<HeaderContent
						hero
						title={hero_heading}
						titleMaxWidth={heading_max_width}
						description={hero_description}
						descriptionHidden={hero_description_hidden}
						descriptionMaxWidth={description_max_width}
						primaryCta={{ label: section_primary_cta_label, link: section_primary_cta_link }}
						secondaryCta={{ label: section_secondary_cta_label, link: section_secondary_cta_link }}
						centered={centered}
						textContrast={text_contrast_mode}
						descriptionClassName={descriptionClassName}
						className={headerClassName}
						headerAs="h1"
					/>

					{/*List of CTAs*/}
					{isNotEmptyArray(items) ? (
						<div
							className={`flex flex-wrap ${
								centered ? 'justify-center' : '-ml-4'
							} gap-x-6 md:gap-x-0 gap-y-5 mx-auto`}
						>
							{items.map((item, i) => {
								return setFilter && item?.cta_label ? (
									<button onClick={() => setFilter(item.filterVal)} className="button" key={i}>
										{item.cta_label}
									</button>
								) : (
									<PrismicLink
										className="button"
										anchor={kebabCase(item.cta_anchor ?? '')}
										link={item.cta_link}
										label={item.cta_label}
										key={i}
										globalData={globalData}
									/>
								)
							})}
						</div>
					) : null}

					{/* children is an optional input: used to render a category nav on the category page and a search bar on the help center page */}
					{children}
				</div>

				{/* Paint Stroke Layout: Image & Paintstroke */}
				{(background_image?.url || background_video?.html) && brushstroke_layout ? (
					<div className="relative container translate-y-40 mb-40 -mt-40 lg:translate-y-96 lg:mb-96 lg:-mt-96 z-20">
						<PaintStrokeBackground
							color={ink_stroke_color}
							className="absolute -top-[25%] -left-[8%] w-[118%]"
						/>
						<div className="relative rounded-lg overflow-hidden bg-g-100 aspect-video">
							{background_video?.html ? (
								<VideoEmbed html={background_video.html} className="video" />
							) : background_image?.url ? (
								<>
									<Image
										src={background_image.url}
										alt={background_image.alt || 'background image'}
										fill
										priority
										className="object-cover"
										sizes="(max-width: 768px) calc(100vw - 32px), 
										(max-width: 1024px) calc(100vw - 48px), 
										(max-width: 1280px) calc(100vw - 80px), 
										(max-width: 1728px) calc(100vw - 128px), 
										1600px"
									/>
									<ImageJsonLd
										contentUrl={background_image.url}
										images={[background_image]}
										creator="Real Thread"
										creditText="Real Thread"
									/>
								</>
							) : null}

							{/* Review Stars (Inside Image) */}
							{showReviewStars ? (
								<div
									className="absolute w-full
									left-1/2 -translate-x-1/2
									top-1/2 -translate-y-1/2
									flex flex-col items-center
									z-10"
								>
									{/* Rating */}
									<span className="block text-yellow-mesh text-2xl md:text-4xl lg:text-[56px] font-serif mb-2 md:mb-6">
										{totalAverage || 0} / 5
									</span>
									{/* Stars */}
									<ReviewStars
										rating={totalAverage || 0}
										className="text-yellow-mesh text-7xl"
										color={theme.extend.colors.yellow.mesh}
										sharpStar
									/>
									{/* Total Reviews */}
									<span className="font-serif text-yellow-mesh text-lg mt-2 md:mt-10">
										{reviewsCount.toLocaleString()} reviews
									</span>
								</div>
							) : null}
						</div>
					</div>
				) : null}

				{/* Bottom Squeegee Effect */}
				{squeegee ? (
					<SqueegeeEffect
						color={background_color}
						squished={!brushstroke_layout && background_image?.url}
						bottomAligned
					/>
				) : null}
			</div>
		</header>
	)
})
BreadcrumbHero.displayName = 'BreadcrumbHero'

export default BreadcrumbHero
